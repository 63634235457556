const IMAGE_SETS_SHOWCASE = {
  students_events_groups: {
    text: "Students • Events • Groups",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/people%2Bevents%2Bgroups.png"
  },
  updates_news_resources: {
    text: "Updates • News • Resources",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/updates%2Bnews%2Bresources.png"
  },
  businesses_perks_events: {
    text: "Startup directory   •   Member perks   •   Events calendar",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/businesses-perks-events-2.png",
  },
  channels_chat_members: {
    text: "Channels • Chat • Members",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/channels-chat-members-2.png",
  },
  events_groups_resources: {
    text: "Events • Groups • Resources",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/events-groups-resources-2.png",
  },
  castings_flow: {
    text: "Complete castings flow: browse, create, submit",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/castings-flow-2.png",
  },
  updates_roster_profile: {
    text: "Announcements • Rosters • Profiles",
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/updates-roster-profile-2.png",
  }
}

export const IMAGE_SETS_SOCIAL_PROOF = {
  ny_la_dc: {
    image: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/NY-LA-DC-min-3.png",
    items: ["New York", "California", "D.C."]
  }  
}

const IMAGE_SETS_HERO = {
  school: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/hero.png",
  model: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/hero-model-2.png",
  startup: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/hero-startup-2.png",
  jewish: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/Jewish+group.png",
  alumni: "https://maya3.s3.us-west-1.amazonaws.com/websiteAssets/images/alumni.png",
}

export const groupConfig = {
  default: {
    groupType: "organization",
    groupTypePlural: "organizations",
    hero: {             // title, subtitle, image
      image: IMAGE_SETS_HERO.startup
    },
    showcase: {         // title, items
      items: [
        { ...IMAGE_SETS_SHOWCASE.channels_chat_members },
        { ...IMAGE_SETS_SHOWCASE.events_groups_resources }
      ]
    },
    benefits: {         // title, items  
      // title: "With it, your school can help students...",
      items: [
        {
          title: "Streamline communications",
          text: "Centralize all communication channels in one platform",
          bullets: ["Group-specific chats", "Organization-wide feed", "Direct messaging"]
        },
        {
          title: "Build community",
          text: "Create a space for members to share updates and interact",
          bullets: ["Share accomplishments", "Ask questions", "Discuss ideas"]
        },
        {
          title: "Manage events",
          text: "Organize and promote events using an integrated calendar"
        },
        {
          title: "Provide resources",
          text: "Offer easy access to important organizational information"
        },
        {
          title: "Share updates",
          text: "Distribute important announcements to all members",
          bullets: ["Send notifications", "Post news", "Communicate changes"]
        },
        {
          title: "Connect members",
          text: "Give members the ability to see everyone across the organization, and connect with them"
        }
      ]
    },
    socialProof: { },      // image, items
  },
  college: {
    groupType: "college",
    groupTypePlural: "colleges",
    hero: {             
      image: IMAGE_SETS_HERO.school
    },
    showcase: {         
      items: [
        { ...IMAGE_SETS_SHOWCASE.students_events_groups },
        { ...IMAGE_SETS_SHOWCASE.updates_news_resources }
      ]
    },
    benefits: {       
      title: "This can help the student body...",
      items: [
        {
          title: "Connect with each other",
          text: "Discover your college community! With thousands of students, finding your tribe can be challenging. Here you can:",
          bullets: ["Meet new people", "Find roommates", "Make friends"]
        },
        {
          title: "Discover campus events",
          text: "See all the best events going on around campus in one easy place. From academic lectures to social gatherings, easily discover and share exciting opportunities with friends."
        },
        {
          title: "Find their tribe",
          text: "Browse a diverse array of student groups, clubs, and organizations. Find communities that align with your interests, passions, and goals."
        },
        {
          title: "Access needed resources",
          text: "Easily access important resources including academic assistance, scholarship information, mental health services, financial aid guidance, and more."
        },
        {
          title: "Stay in the loop",
          text: "Never miss out on need-to-know info. Student government can push announcements, news, and updates straight to the student body."
        },
        {
          title: "Chat with the student body",
          text: "Engage in school-wide conversations, access student news sources, and stay connected with the latest happenings."
        }
      ]
    },
  },
  'talent-agency': {
    groupType: "talent agency",
    groupTypePlural: "talent agencies",
    hero: {             
      image: IMAGE_SETS_HERO.model
    },
    showcase: {         
      items: [{ ...IMAGE_SETS_SHOWCASE.castings_flow }, { ...IMAGE_SETS_SHOWCASE.updates_roster_profile }]
    },
    benefits: {       
      items: [
        {
          title: "Get your castings seen",
          text: "Send real-time notifications about new casting opportunities directly to your talent pool, ensuring they never miss a chance to shine.",
        },
        {
          title: "Get more submissions",
          text: "Make it easy on the talent to submit. They can effortlessly browse castings and submit with just a few taps."
        },
        {
          title: "Automate submissions",
          text: "With each submission, we automatically generate a professional submission form. All you ahve to do is simply forward to the casting director."
        },
        {
          title: "Centralize communications",
          text: "Avoid using crowded platforms like email. Make sure your talent sees your messages by using an exclusive app."
        },
        {
          title: "Access talent profiles",
          text: "Maintain detailed, up-to-date profiles for all your talent that you can easily access as needed."
        },
        {
          title: "Have a unique recruiting tool",
          text: "Stand out in a competitive market. Use this custom platform as a distinctive selling point to attract and retain top talent for your agency."
        }
      ]
    },
  },
  'startup-accelerator': {
    groupType: "startup accelerator",
    groupTypePlural: "startup accelerators",
    hero: {             
      image: IMAGE_SETS_HERO.startup
    },
    showcase: {         
      items: [{ ...IMAGE_SETS_SHOWCASE.channels_chat_members },{ ...IMAGE_SETS_SHOWCASE.businesses_perks_events }]
    },
    benefits: {       
      items: [
        {
          title: "Cultivate community",
          text: "Boost the value of your accelerator by fostering engaged, vibrant communities through",
          bullets: ["Cohort chats", "Community wide feed", "Instury / topic discussions"]
        },
        {
          title: "Create networking opportunities",
          text: "Enable members to discover and connect with peers across all cohorts, facilitating:",
          bullets: ["Co-founder matching", "Talent acquisition", "Advisor partnerships"]
        },
        {
          title: "Accelerate startup success",
          text: "Make it easy for your startups to draw upon the resources and knowledge base of your accelerator."
        },
        {
          title: "Deliver valuable perks",
          text: "Aggregate and showcase exclusive discounts from crucial technology providers, helping cash-strapped startups maximize their resources."
        },
        {
          title: "Broadcast accelerator progress",
          text: "Keep your entire community informed and engaged with:",
          bullets: ["Timely announcements", "Centralized media hub", "Success stories"]
        },
        {
          title: "Showcase network value",
          text: "This is your own Y Combinator BookFace. Easily demonstrate network value to prospective startups and partners."
        }
      ]
    },
  },
  'jewish-groups': {
    groupType: "Jewish group",
    groupTypePlural: "Jewish groups",
    hero: {             
      subtitle: "We'll build a custom app for your group, for free, and have it up this week.",
      image: IMAGE_SETS_HERO.jewish
    },
    showcase: {     
      title: "It has everything your group needs, all in one place",    
      items: [
        { ...IMAGE_SETS_SHOWCASE.channels_chat_members },
        { ...IMAGE_SETS_SHOWCASE.events_groups_resources }
      ]
    },
    benefits: {      
      title: "With it, you can...", 
      items: [
        {
          title: "Strengthen community bonds",
          text: "Give members an easy way to connect and share with each other when not in person.",
        },
        {
          title: "Facilitate connections",
          text: "Empower members to see everyone across the community and forge new relationships.",
        },
        {
          title: "Boost community engagement",
          text: "Spread the word about community events, maximizing attendance and participation."
        },
        {
          title: "Share resources",
          text: "Provide easy access to important community information and resources, all in one place."
        },
        {
          title: "Reinforce Jewish values",
          text: "Give members a platform to share Jewish news, traditions, and values. This is especially important in an increasingly digital world.",
          // bullets: ["Timely announcements", "Centralized media hub", "Success stories"]
        },
      ]
    },
    socialProof: {
      title: "Which is why groups across the country have apps with us",
    }
  },
  'alumni-groups': {
    groupType: "alumni group",
    groupTypePlural: "alumni groups",
    hero: {             
      image: IMAGE_SETS_HERO.alumni
    },
    showcase: {
      items: [
        { ...IMAGE_SETS_SHOWCASE.channels_chat_members },
        { ...IMAGE_SETS_SHOWCASE.events_groups_resources }
      ]
    },
    benefits: {
      items: [
        {
          title: "Centralize communications",
          text: "Keep your alumni connected through multiple channels in one platform",
        },
        {
          title: "Boost event attendance",
          text: "Get the word out about your alumni events -- maximizing attendance and alumni engagement.",
        },
        {
          title: "Keep alumni informed",
          text: "Send out important announcements and updates to all alumni, ensuring they stay in the loop.",
        },
        {
          title: "Provide value",
          text: "Be there for alumni by continually providing news and resources that are relevant to them.",
        },
        {
          title: "Connect alumni",
          text: "Empower networking within your graduate community with a browseable directory of alumni.",
        },
        {
          title: "Gain insights",
          text: "Get engagement analytics to improve alumni relations and participation",
        }
      ]
    },
    socialProof: { }, 
  },



};
