import './main.css';
import React, { useRef, useState } from 'react';
import { MdArrowForward, MdArrowDownward } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import useGroupConfig from '../hooks/useGroupConfig';
import { IMAGE_SETS_SOCIAL_PROOF } from '../hooks/groupConfig';
import emailjs from '@emailjs/browser';

const handleClick = (e) => {
  e.preventDefault();
  const target = document.querySelector('#divider05');
  if (target) {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
};

const chunk = (array, size) => {
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
};

const LandingScreen = () => {
  const config = useGroupConfig();

  const HeroSection = ({  }) => {
    return (
      <div id="container07" className="style2 container columns">
        <div className="wrapper">
          <div className="inner" data-onvisible-trigger="1">
            <div>
              <h2 className="style3"
                >{ config?.hero?.title ?? ( <>A custom app built specifically for your {config.groupType} </> )}
              </h2>
              <p className="style9" style={{ maxWidth: "80%" }}
                >{config?.hero?.subtitle ?? `We'll build a custom app for your ${config.groupType}, for free, and have it up this week.`}
              </p>
              <ul className="style2 buttons">
                <li>
                  <a href="#form" className="button n01" onClick={handleClick}>
                    <MdArrowDownward aria-label="Arrow Right (Light)" />
                    <span className="label"
                      >Get your custom app
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div >
              <div className="heroSpacer" />
              <div className="style1 image" >
                <span className="frame deferred">
                  <img 
                    src={config.hero.image} 
                    // data-src={config.hero.image} 
                    alt="" 
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const ShowcaseSection = ({ }) => {
    return (
      <>
        <hr id="divider03" className="style1 full screen" />
        <p id="text05" className="style4"
          >#1
        </p>
        <h2 id="text06" className="style3">
        <span className="p"
            >{ config?.showcase?.title ?? `It has everything your ${config.groupType} needs, all in one place`}
          </span>
        </h2>
        <p id="text13" className="style1">&nbsp;</p>
        <p id="text02" className="style1"
          >{config.showcase.items[0].text}
        </p>
        <div id="image09" className="style2 image" data-position="center">
          <span className="frame deferred">
            <img 
              src={config.showcase.items[0].image}
              data-src={config.showcase.items[0].image} 
              alt="" 
            />
          </span>
        </div>
        <p id="text22" className="style1">&nbsp;</p>
        <p id="text01" className="style1"
          >{config.showcase.items[1].text}
        </p>
        <div id="image03" className="style2 image" data-position="center">
        <span className="frame deferred">
            <img 
              src={config.showcase.items[1].image}
              data-src={config.showcase.items[1].image} 
              alt="" 
            />
          </span>
        </div>
      </>
    )
  }

  const BenefitsSection = ({  }) => {
    const Tile = ({ item }) => (
      <div className='bubbles' >
        <h3 className='style6'>{item.title}</h3>
        <p className='style5'>{item.text}</p>
        {item.bullets && (
          <ul className="style5">
            {item.bullets.map((bullet, i) => (
              <li className="style5" key={i}>{bullet}</li>
            ))}
          </ul>
        )}
      </div>
    );
  
    const chunkedItems = chunk(config.benefits.items, 2);
  
    return (
      <>
        <hr id="divider02" className="style1 full screen" />
        <p id="text33" data-scroll-id="form" data-scroll-behavior="default" data-scroll-offset="0" data-scroll-speed="3" data-scroll-invisible="1" className="style4">
          #2
        </p>
        <h2 className="style3">
          {config.benefits.title ?? `With it, your ${config.groupType} can...`}
        </h2>
        <p className="style1">&nbsp;</p>

        {chunkedItems.map((chunk, index) => (
          <div key={index} className='bubbles__container' >
            {chunk.map((item, itemIndex) => (
              <Tile key={`${index}-${itemIndex}`} item={item} />
            ))}
          </div>
        ))}
      </>
    );
  };

  const SocialProofSection = ({ }) => {
    let effectiveObj = config?.socialProof?.image && config?.socialProof?.items ? config.socialProof : IMAGE_SETS_SOCIAL_PROOF?.ny_la_dc;

    return (
      <>
        <hr id="divider01" className="style1 full screen"/>
        <p id="text31" className="style4"
          >#3
        </p>
        <h2 id="text06" className="style3"
          >{config?.socialProof?.title ?? `Which is why ${config.groupTypePlural} across the country have apps with us`}
        </h2>
        <div className="style3 image" >
          <span className="frame deferred"><img src={effectiveObj?.image} alt="" /></span>
        </div>
        <div id="container05" className="style1 container columns" >
          <div className="wrapper">
            <div className="inner" data-onvisible-trigger="1">
              {effectiveObj.items.map((item, index) => (
                <div key={index}><p id={`text${10 + index}`} className="style6" style={{textAlign: "center"}}
                  >{item}
                </p></div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }

  const VersusSection = ({  }) => {
    const Tile = ({ title, bullets, cost, duration, process, isHighlighted }) => (
      // <div style={{ display: "flex", flexDirection: "column", opacity: isHighlighted ? 1 : 0.7, }}>
      // <h2 className="style6" style={{textAlign: "center"}}
      //   >{title}
      // </h2>
      <div
        className='bubbles'
        style={{
          backgroundColor: !isHighlighted && "transparent",
          // backgroundColor: "transparent",
          // boxShadow: isHighlighted ? '0 0 20px rgba(255, 255, 255, 0.3)' : 'none',
          border: isHighlighted ? '2px solid #ffffff' : '1px solid #ffffff30',
          opacity: isHighlighted ? 1 : 0.6,
          borderRadius: '20px',
          padding: '30px',
          margin: '0 10px 0 10px',
        }}
      >
        <h3 className='style6' style={{textAlign: "center", textTransform: "uppercase"}}
          >{title}
        </h3>
        <h3 className='style6' 
          >{cost.label}
        </h3>
        <ul className="style5" style={{ marginTop: '-10px', marginBottom: '5px'}}>
          <li className='style5'>
            {cost.value}
          </li>
        </ul>

        <h3 className='style6' 
          >{duration.label}
        </h3>
        <ul className="style5" style={{ marginTop: '-10px', marginBottom: '5px'}}>
          <li className='style5'>
            {duration.value}
          </li>
        </ul>
        
        <h3 className='style6' 
          >{process.label}
        </h3>
        <ol className="style5" style={{ marginTop: '-10px', marginBottom: '5px'}}>
          {process.value.map((bullet, index) => (
            <li key={index} className='style5'>
              {bullet}
            </li>
          ))}
        </ol>
      </div>
      // </div>
    );
    
  
    return (
      <>
        <hr className="style1 full screen" />
        <p className="style4" >
          #4
        </p>
        {/* <h2 className="style3" 
          >You can get your custom app this week, for free
        </h2> */}
        <h2 className="style3" 
          >Custom apps typically cost $100k+ and take 6-12 months
        </h2>
        <p className="style9" style={{ maxWidth: "80%" }}
          >With us, you get your custom app <u>for free</u> and <u>this week</u>
        </p>
        <p id="text34" className="style1">&nbsp;</p>
        <div className='bubbles__container'>
          <Tile
            title="Groupspace"
            cost={{ label: "Free", value: "No cost" }}
            duration={{ label: "Quick", value: "Get your app this week" }}
            process={{ label: "Easy", value: [
              `You tell us about your ${config.groupType}`,
              "We build your app"
            ]}}
            isHighlighted={true}
          />

          <h3 className='style6' style={{textAlign: "center", paddingTop: '5px'}}
            >vs.
          </h3>

          <Tile
            title="Typical process"
            cost={{ label: "Expensive", value: "$100,000+" }}
            duration={{ label: "Slow", value: "Wait 6-12 months" }}
            process={{ label: "Difficult", value: [
              "Secure a huge budget ($100,000+)",
              "Spend weeks interviewing developers",
              "Review countless designer portfolios",
              "Figure out who to hire",
              "Attend many planning meetings",
              "Argue over the vision of the app",
              "Add all this to your todo list",
              "Begin the development process",
              "Deal with setbacks and scope creep",
              "Wait anxiously for 6-12 months",
              "Receive a product that isn't right",
              "Spend more time and money",
              "Tackle unexpected integration issues",
              "Secure more money for maintenace",
              "Worry about security vulnerabilities",
              "Realize your app is already outdated",
              "Start the whole process over again",
            ]}}
          />
        </div>
      </>
    )
  }


  const FormSection = ({  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setSubmitStatus(null);

      emailjs
        .sendForm('service_luqzbsj', 'template_3s21ihp', form.current, {
          publicKey: 'fcXECPze2sKiu5pmB',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            setSubmitStatus('success');
            form.current.reset(); // Clear the form fields
          },
          (error) => {
            console.log('FAILED...', error.text);
            setSubmitStatus('error');
          }
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    };

    return (
      <>
        <hr id="divider05" className="style1 full screen"/>
        <p id="text33" data-scroll-id="form" data-scroll-behavior="default" data-scroll-offset="0" data-scroll-speed="3" data-scroll-invisible="1" className="style4">#5</p>
        <h2 id="text21" className="style3"><span className="p">Interested?<br />It's easy to start</span></h2>
        <p id="text07" className="style9">Just fill out the form below and we'll be in touch in a few hours to set it up! <u>And remember, it's free</u></p>
        <p id="text34" className="style1">&nbsp;</p>
        <form id="form01" ref={form} onSubmit={sendEmail}>
          <div className="inner">
            <div className="field">
              <input type="text" name="name" id="form01-name" placeholder="Name" maxLength="128" required />
            </div>
            <div className="field">
              <input type="email" name="email" id="form01-email" placeholder="Email" maxLength="128" required />
            </div>
            <div className="field">
              <textarea
                data-gramm_editor="false"
                name="message"
                id="form01-message"
                placeholder={`Tell us about your ${config.groupType}!`}
                maxLength="16384"
                required
              ></textarea>
            </div>
            
            <input type="hidden" name="groupType" value={config?.groupType} />


            <div className="actions">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
          <input type="hidden" name="id" value="form01" />
        </form>
        { submitStatus === 'success' && (
          <p className="style9">Thank you for your submission! We'll be in touch today.</p>
        )}
        {submitStatus === 'error' && (
          <p className="style1 error-message">An error occurred. Please try again or contact us directly.</p>
        )}
        <p className="style1">&nbsp;</p>
        <p className="style1">&nbsp;</p>
        <p className="style2">&nbsp;</p>
        <p className="style2">&nbsp;</p>
        <p className="style2">© Pillar Technology LLC. All rights reserved.</p>
      </>
    );
  };

  

  return (
    <>
      <Helmet>
        <title>{config.meta.title}</title>
        <meta name="description" content={config.meta.description} />
        <meta name="keywords" content={config.meta.keywords.join(', ')} />
        <meta property="og:image" content={config.meta.ogImage} />
        <link rel="canonical" href={config.meta.canonicalUrl} />
      </Helmet>
      
      <div id="wrapper">
        <div id="main">
          <div className="inner">
            <HeroSection />
            <ShowcaseSection />
            <BenefitsSection />
            <SocialProofSection />
            <VersusSection />
            <FormSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingScreen;





// const BenefitsSection = ({ benefits }) => {
//   const midpoint = Math.ceil(benefits.items.length / 2);
//   const leftColumn = benefits.items.slice(0, midpoint);
//   const rightColumn = benefits.items.slice(midpoint);

//   const renderBenefit = (benefit, index, isLastInColumn) => (
//     <div key={index}>
//       {/* <hr className="style3" /> */}
//       <h3 className="style6">{benefit.title}</h3>
//       <p className="style5">{benefit.text}</p>
//       {benefit.bullets && (
//         <ul className="style5">
//           {benefit.bullets.map((bullet, i) => (
//             <li className="style5" key={i}>{bullet}</li>
//           ))}
//         </ul>
//       )}
//       {!isLastInColumn && <hr className="style4 full screen" />}
//     </div>
//   );

//   return (
//     <>
//       <hr id="divider02" className="style1 full screen"/>
//       <p id="text33" data-scroll-id="form" data-scroll-behavior="default" data-scroll-offset="0" data-scroll-speed="3" data-scroll-invisible="1" className="style4"
//         >#2
//       </p>
//       <h2 className="style3">
//         <span className="p"
//           >{benefits.title}
//         </span>
//       </h2>
//       <div id="container03" className="style3 container columns">
//         <div className="wrapper">
//           <div className="inner" data-onvisible-trigger="1">
//             <div className="benefits-column">
//               {leftColumn.map((benefit, index) => 
//                 renderBenefit(benefit, index, index === leftColumn.length - 1)
//               )}
//             </div>
//             <div className="benefits-column">
//               {rightColumn.map((benefit, index) => 
//                 renderBenefit(benefit, index, index === rightColumn.length - 1)
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
