import { useParams } from 'react-router-dom';
import { groupConfig } from './groupConfig';


const useGroupConfig = () => {
  const { name } = useParams();
  const baseConfig = groupConfig[name] || groupConfig.default;
  console.log(name)

  console.log(groupConfig?.['talent-agency'])

  const enhancedConfig = {
    ...baseConfig,
    meta: {
      title: `Custom apps for ${baseConfig.groupTypePlural || 'groups'}`,
      description: `GroupSpace: Get a custom app built specifically for your ${baseConfig.groupType || 'group'}.`,
      keywords: [ "custom app", baseConfig?.groupType, "community", "app", "custom"],
      ogImage: `https://groupspace.com/images/${name || 'default'}-og.jpg`,
      canonicalUrl: `https://groupspace.com/g/${name || 'default'}`,
    },
  };

  return enhancedConfig;
};

export default useGroupConfig;