import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { Routes } from './navigation/routes';
import ErrorScreen from './screens/ErrorScreen';
import LandingScreen from './screens/LandingScreen3';
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom";
import ProtectedRoute from './navigation/ProtectedRoute';
import { ThemeProvider } from '@mui/material';
import theme from './contexts/ThemeProvider';
import ReactGA from "react-ga4";


ReactGA.initialize("G-54F08M4G3Z");


const App = () => {

  const router = createBrowserRouter([
    {
      path: '/', 
      element: <LandingScreen />,
      errorElement: <ErrorScreen />,
    },
    {
      path: '/g/:name', 
      element: <LandingScreen />,
      errorElement: <ErrorScreen />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;

